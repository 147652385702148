<template>
	<div style="position: relative; height: 50px">
		<div
			class="
				bg-secondary
				text-white
				h-100
				w-100
				d-flex
				justify-content-center
				align-items-center
				rounded-left
			"
		>
			<span>{{ text }}</span>
		</div>
		<div
			class="arrow-right"
			style="position: absolute; right: -25px; top: 0"
		></div>
	</div>
</template>

<script>
export default {
	props: {
		text: String,
	},
};
</script>

<style scoped>
.arrow-right {
	width: 0;
	height: 0;
	border-top: 25px solid transparent;
	border-bottom: 25px solid transparent;

	border-left: 25px solid #072648;
}
</style>