<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <b-button variant="secondary" :disabled="printing" @click="exportToPDF">
          <b-spinner
            v-if="printing"
            small
            class="text-white mr-1"
            label="Loading..."
          ></b-spinner>
          <i class="mdi mdi-printer mr-1"></i> Télécharger</b-button
        >
      </div>
    </div>

    <section ref="document">
      <!-- First Page -->
      <div class="page page-1 container-fluid rounded py-4 px-5 mt-4 bg-white">
        <div class="row mt-3">
          <p class="my-auto mr-auto">
            <i :class="$route.meta.iconClass"></i>
            <span
              style="font-size: 22px; color: #7f7f7f"
              class="font-weight-light"
            >
              Fiche Application :
            </span>

            <span style="font-size: 25px" class="font-weight-bold">{{
              APPLICATION.name
            }}</span>
            <!-- (PDF) -->
          </p>
        </div>
        <div class="row my-4">
          <div class="col-12">
            <div class="">
              <div class="row">
                <div class="col-12 custom-shadow-md py-2 col-md-4">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p
                      class="h4 text-secondary my-auto mr-auto"
                      style="margin: 6.656px 0 !important"
                    >
                      Informations générales
                    </p>
                  </div>
                  <div class="col-12 p-0">
                    <hr class="bg-secondary mb-3 mt-1" />
                  </div>
                  <div class="my-4">
                    <span>Nom : </span>
                    <span class="font-weight-bold">{{ APPLICATION.name }}</span>
                  </div>
                  <div class="my-4">
                    <span>Libellé court : </span>
                    <span class="font-weight-bold">{{
                      APPLICATION.libellecour
                    }}</span>
                  </div>
                  <div class="my-4">
                    <span>Statut : </span>
                    <span class="font-weight-bold">{{
                      APPLICATION.status
                    }}</span>
                  </div>
                  <div class="my-4">
                    <span>Ligne métier : </span>
                    <span class="font-weight-bold">{{
                      APPLICATION.businessLine
                        ? APPLICATION.businessLine.length
                        : 0
                    }}</span>
                  </div>
                  <div class="my-4">
                    <span>Type SI : </span>
                    <span class="font-weight-bold">{{
                      APPLICATION.typeSi || "-"
                    }}</span>
                  </div>
                  <div class="my-4">
                    <span>Patrimoine : </span>
                    <span class="font-weight-bold">{{
                      APPLICATION.patrimoine ? APPLICATION.patrimoine.name : "-"
                    }}</span>
                  </div>

                  <div class="my-4">
                    <span>PO : </span>
                    <span class="font-weight-bold">
                      {{ APPLICATION.po ? APPLICATION.po.firstname : "" }}
                      {{ APPLICATION.po ? APPLICATION.po.lastname : "" }}
                    </span>
                  </div>
                  <div class="my-4">
                    <span>Libellé long : </span>
                    <span class="font-weight-bold">{{
                      APPLICATION.description
                    }}</span>
                  </div>
                  <div class="my-4">
                    <span>Type d'applications : </span>
                    <span class="font-weight-bold">{{ APPLICATION.type }}</span>
                  </div>
                  <div class="my-4">
                    <span>Portée : </span>
                    <span class="font-weight-bold">{{
                      APPLICATION.portee || "-"
                    }}</span>
                  </div>
                  <div class="my-4 d-flex">
                    <span class="d-block my-auto mr-1">Taux : </span>
                    <b-progress
                      max="100"
                      class="col-md-8 p-0"
                      show-progress
                      height="15px"
                    >
                      <b-progress-bar
                        :value="APPLICATION.taux"
                        :label="`${APPLICATION.taux}%`"
                        :style="`background-color: ${
                          APPLICATION.taux > 20 && APPLICATION.taux <= 70
                            ? '#f5f245'
                            : APPLICATION.taux > 70
                            ? '#23d92c'
                            : '#f5ba45'
                        }`"
                      ></b-progress-bar>
                    </b-progress>
                  </div>
                </div>

                <div class="col-12 custom-shadow-md py-2 col-md-4">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p
                      class="h4 text-secondary my-auto mr-auto"
                      style="margin: 6.656px 0 !important"
                    >
                      Documents
                    </p>
                  </div>
                  <div class="col-12 p-0">
                    <hr class="bg-secondary mb-3 mt-1" />
                  </div>
                  <div
                    class="text-center text-gray col-12 p-1"
                    v-if="
                      !APPLICATION.fileApplications ||
                      !APPLICATION.fileApplications.length
                    "
                  >
                    Aucun document
                  </div>
                  <b-list-group v-else class="mt-3">
                    <b-list-group-item
                      v-for="(livrable, index) in APPLICATION.fileApplications"
                      :key="index"
                      class="d-flex"
                      ><i
                        class="fa my-auto pr-1 fa-paperclip"
                        aria-hidden="true"
                      ></i>
                      <a
                        target="_blank"
                        :href="IMG_URL + livrable.fileUrl"
                        class="text-truncate"
                      >
                        {{ livrable.filename }}</a
                      >
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div class="col-12 custom-shadow-md py-2 col-md-4">
                  <div>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <p
                        class="h4 text-secondary my-auto mr-auto"
                        style="margin: 6.656px 0 !important"
                      >
                        Couvertures
                      </p>
                    </div>
                    <div class="col-12 p-0">
                      <hr class="bg-secondary mb-3 mt-1" />
                    </div>

                    <div class="row m-0 p-0" style="gap: 5px">
                      <div
                        class="text-center text-gray col-12 p-1"
                        v-if="
                          !APPLICATION.couvertures ||
                          !APPLICATION.couvertures.length
                        "
                      >
                        Aucune couverture
                      </div>
                      <div
                        v-else
                        style="
                          height: 50px;
                          position: relative;
                          overflow: visible;
                        "
                        class="custom-shadow-md"
                        v-for="(covert, index) in APPLICATION.couvertures"
                        :key="index"
                      >
                        <p class="my-auto">
                          <country-flag
                            :country="covert.countryCode"
                            size="small"
                          />{{ covert.country || "" }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <p
                        class="h4 text-secondary my-auto mr-auto"
                        style="margin: 6.656px 0 !important"
                      >
                        Pays Responsables
                      </p>
                    </div>
                    <div class="col-12 p-0">
                      <hr class="bg-secondary mb-3 mt-1" />
                    </div>

                    <div class="row m-0 p-0" style="gap: 5px">
                      <div
                        class="text-center text-gray col-12 p-1"
                        v-if="
                          !APPLICATION.couversturesResp ||
                          !APPLICATION.couversturesResp.length
                        "
                      >
                        Aucune Pays Responsables
                      </div>
                      <div
                        v-else
                        style="
                          height: 50px;
                          position: relative;
                          overflow: visible;
                        "
                        class="custom-shadow-md"
                        v-for="(covert, index) in APPLICATION.couversturesResp"
                        :key="index"
                      >
                        <p class="my-auto">
                          <country-flag
                            :country="covert.countryCode"
                            size="small"
                          />{{ covert.country || "" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12 p-0 pr-1 col-md-6">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Traîtement GDPR</p>
              <b-table
                :items="traitmentsList"
                :fields="gdpr.tableFields"
                responsive
                striped
                show-empty
              >
                <!-- SHOW IF TABLE IS EMPTY -->
                <template #empty>
                  <div class="text-center text-gray">Table vide</div>
                </template>
              </b-table>
            </div>
          </div>
          <div class="col-12 p-0 pl-1 col-md-6">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">API Exposés</p>

              <div class="row">
                <div
                  class="text-gray d-flex h2 text-center w-100"
                  v-if="!apisList.length"
                >
                  <i
                    class="mdi my-auto ml-auto mdi-information-outline my-auto"
                  ></i>
                  <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
                </div>
                <div
                  v-for="{ name, id } in apisList"
                  :key="id"
                  class="col-12 p-1 col-md-4"
                >
                  <div
                    class="
                      custom-shadow-md
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      :src="cogApi"
                      alt="Api"
                      style="width: 40px; height: 40px"
                    />
                    <span class="mt-2">{{ name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12 p-0 pr-1 col-md-6">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Capacité SI</p>
              <div class="row">
                <div
                  class="text-gray d-flex h2 text-center w-100"
                  v-if="!capacitesList.length"
                >
                  <i
                    class="mdi my-auto ml-auto mdi-information-outline my-auto"
                  ></i>
                  <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
                </div>
                <div
                  v-for="{ name, id } in capacitesList"
                  :key="id"
                  class="col-12 col-md-4 p-1"
                >
                  <div
                    class="
                      custom-shadow-md
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i class="mdi mdi-cube-outline" style="font-size: 28px"></i>
                    <span class="mt-2">{{ name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 pl-1 col-md-6">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Fonctionnalités</p>
              <b-table
                responsive
                striped
                :items="fonctionsList"
                :fields="fonction.tableFields"
                show-empty
              >
                <!-- SHOW IF TABLE IS EMPTY -->
                <template #empty>
                  <div class="text-center text-gray">Table vide</div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <!-- </div>

       Second Page 
      <div class="page page-2 container-fluid rounded py-4 px-5 bg-white mt-5"> -->
        <div class="row my-4">
          <div class="col-12 p-0 pr-1 col-md-6">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Les Référents</p>
              <div class="row px-1">
                <div
                  class="text-gray d-flex h2 text-center w-100"
                  v-if="!referentsList.length"
                >
                  <i
                    class="mdi my-auto ml-auto mdi-information-outline my-auto"
                  ></i>
                  <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
                </div>
                <div
                  v-for="referent in referentsList"
                  :key="referent.id"
                  class="col-12 p-1 col-md-3"
                >
                  <!-- {{ referent }} -->
                  <user-card :referent="referent" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 pl-1 col-md-6">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Bénéficiaires</p>
              <div class="row px-1">
                <div
                  class="text-gray d-flex h2 text-center w-100"
                  v-if="!beneficairesList.length"
                >
                  <i
                    class="mdi my-auto ml-auto mdi-information-outline my-auto"
                  ></i>
                  <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
                </div>
                <div
                  v-for="{ name, volume, id } in beneficairesList"
                  :key="id"
                  class="col-12 p-1 col-md-4"
                >
                  <div
                    class="
                      custom-shadow-md
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i
                      class="mdi mdi-account-group"
                      style="font-size: 28px"
                    ></i>
                    <span class="mt-2">{{ name }}</span>
                    <span class="mt-2">{{ volume }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12 col-md-6 p-0 pr-1">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Processus supportés</p>
              <div class="row px-1">
                <div
                  class="text-gray d-flex h2 text-center w-100"
                  v-if="!processusList.length"
                >
                  <i
                    class="mdi my-auto ml-auto mdi-information-outline my-auto"
                  ></i>
                  <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
                </div>
                <div
                  v-for="{ id, name } in processusList"
                  :key="id"
                  class="col-12 p-1 col-md-4"
                >
                  <BlockRightTriangle :text="name" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 p-0 pl-1">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Données Manipulées</p>
              <div class="row">
                <div class="col-12">
                  <b-table
                    responsive
                    :items="objectsList"
                    :fields="data.tableFields"
                    striped
                    show-empty
                  >
                    <!-- SHOW IF TABLE IS EMPTY -->
                    <template #empty>
                      <div class="text-center text-gray">Table vide</div>
                    </template>
                    <template #cell(dcp)="data">
                      <b-badge
                        :variant="
                          dcp(data.item) === 'Oui' ? 'danger' : 'success'
                        "
                        >{{ dcp(data.item) }}</b-badge
                      >
                    </template>
                    <template v-slot:cell(sensible)="data">
                      <b-badge
                        :variant="sensible(data.item) ? 'danger' : 'success'"
                        >{{ sensible(data.item) }}</b-badge
                      >
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12 p-0 pr-1 col-md-6">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Composants techniques</p>
              <div class="row px-1">
                <div
                  class="text-gray d-flex h2 text-center w-100"
                  v-if="!composantsList.length"
                >
                  <i
                    class="mdi my-auto ml-auto mdi-information-outline my-auto"
                  ></i>
                  <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
                </div>
                <div
                  v-for="{ name, id } in composantsList"
                  :key="id"
                  class="col-12 p-1 col-md-4"
                >
                  <div
                    class="
                      custom-shadow-md
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i class="mdi mdi-tools" style="font-size: 28px"></i>
                    <span class="mt-2">{{ name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p-0 pl-1 col-md-6">
            <div class="custom-shadow-md h-100">
              <p class="h4 mb-3">Transformations</p>
              <div class="row">
                <div class="col-12">
                  <!-- {{ transformationsList }} -->
                  <b-table
                    :items="transformationsList"
                    :fields="transformation.tableFields"
                    responsive
                    striped
                    show-empty
                  >
                    <!-- SHOW IF TABLE IS EMPTY -->
                    <template #empty>
                      <div class="text-center text-gray">Table vide</div>
                    </template>
                    <template #cell(fdr)="data">
                      <span>
                        {{ data.item.initiative.feuilleRoute.name }}
                      </span>
                    </template>
                    <template #cell(initiative)="data">
                      <span>
                        {{ data.value.libelle }}
                      </span>
                    </template>
                    <template #cell(po)="data">
                      <span v-if="data.item.initiative">
                        {{
                          data.item.initiative.responsable
                            ? data.item.initiative.responsable.firstname +
                              " " +
                              data.item.initiative.responsable.lastname
                            : "-"
                        }}
                      </span>
                    </template>
                    <template #cell(patrimoine)="data">
                      <span v-if="data.item.initiative.feuilleRoute.patrimoine">
                        {{
                          data.item.initiative.feuilleRoute.patrimoine.name ||
                          "-"
                        }}
                      </span>
                    </template>

                    <template #cell(description)="data">
                      <span>
                        {{ data.value || "-" }}
                      </span>
                    </template>
                    <template #cell(end_date)="data">
                      <span>
                        {{ data.value || "-" }}
                      </span>
                    </template>
                    <template #cell(type)="data">
                      <b-badge
                        :variant="
                          data.value.toLowerCase() === 'création'
                            ? 'success'
                            : data.value.toLowerCase() === 'evolution'
                            ? 'warning'
                            : 'danger'
                        "
                      >
                        {{ data.value }}
                      </b-badge>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="false" class="row my-4">
          <div class="col-12">
            <div class="custom-shadow-md">
              <p class="h4">Interactions</p>
              <div class="row">
                <div class="col-12 col-sm-4 col-md-2">
                  <div
                    class="
                      custom-shadow-md
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i class="mdi mdi-cogs" style="font-size: 28px"></i>
                    <span class="mt-2">SAP</span>
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-2">
                  <div
                    class="
                      custom-shadow-md
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i class="mdi mdi-cogs" style="font-size: 28px"></i>
                    <span class="mt-2">SAP</span>
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-2">
                  <div
                    class="
                      custom-shadow-md
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i class="mdi mdi-cogs" style="font-size: 28px"></i>
                    <span class="mt-2">SAP</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CogApi from "@/assets/images/application/cog-api.png";
import BlockRightTriangle from "@/components/custom/BlockRightTriangle.vue";
import UserCard from "@/components/custom/UserCard.vue";
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";
import { IMG_URL } from "@/helpers/services/api";
import { maxBy } from "lodash";

export default {
  components: { BlockRightTriangle, UserCard, CountryFlag },
  data: () => ({
    cogApi: CogApi,
    printing: false,
    IMG_URL,
    gdpr: {
      tableFields: [
        { key: "name", label: "Traitement", sortable: false },
        { key: "status", label: "Ëtat", sortable: false },
      ],
    },
    fonction: {
      tableFields: [
        { key: "name", label: "Fonctions", sortable: false },
        { key: "description", label: "Description", sortable: false },
        {
          key: "entrepriseValeur",
          label: "Entreprise Valeur",
          sortable: false,
        },
      ],
    },
    data: {
      tableFields: [
        { key: "name", label: "Objets Métier", sortable: false },
        { key: "dcp", label: "DCP", sortable: false },
        { key: "sensible", label: "Sensible", sortable: false },
      ],
    },
    transformation: {
      tableFields: [
        { key: "fdr", label: "Feuilles de routes", sortable: false },
        { key: "initiative", label: "Initiatives", sortable: false },
        { key: "po", label: "PO", sortable: false },
        { key: "patrimoine", label: "Patrimoine", sortable: false },
        { key: "type", label: "Type Impact", sortable: false },
        {
          key: "description",
          label: "Description Impact",
          sortable: false,
        },
        { key: "end_date", label: "Date de fin", sortable: false },
      ],
      tableItems: [
        {
          fdr: "Fdr 1",
          initiatives: "Initiative 1",
          po: "Velit",
          patrimoine: "Soluta",
          impact_type: "Création",
          impact_description: "Omnis non sit eos quisquam.",
          end_date: "12-12-2021",
        },
        {
          fdr: "Fdr 2",
          initiatives: "Initiative 1",
          po: "Velit",
          patrimoine: "Soluta",
          impact_type: "Suppression",
          impact_description:
            "Nesciunt doloremque explicabo id nam corporis maiores quis et rerum.",
          end_date: "12-12-2021",
        },
      ],
    },
  }),
  methods: {
    maxBy,
    exportToPDF() {
      this.printing = true;

      html2pdf()
        .set({
          margin: 1,
          filename: `Fiche application: ${this.APPLICATION.name}.pdf`,
          image: { type: "jpg", quality: 0.95 },
          html2canvas: {
            //  scale: 2,
            dpi: 192,
          },
          jsPDF: { unit: "in", format: "A1", orientation: "portrait" },
          pagebreak: { mode: "avoid-all", after: ".page" },
          // enableLinks: false,
        })
        .from(this.$refs.document)
        .save()
        .then(() => {
          this.printing = false;
        });
    },
    dcp(objet) {
      return !objet ||
        !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) =>
          attribut.dcp ? attribut.dcp === "Non" : false
        )
        ? "Non"
        : "Oui";
    },
    sensible(objet) {
      return !objet ||
        !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) =>
          attribut.sensible ? attribut.sensible === 0 : true
        )
        ? 0
        : maxBy(objet.attributs, (attribut) => attribut.sensible || 0).sensible;
    },
  },
  created() {
    this.$store.dispatch("application/fetchApplication", this.$route.params.id);
    this.$store.dispatch(
      "application/fetchApplicationApi",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationCapacites",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationComposants",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationProcessus",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationBeneficaires",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationReferents",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationTraitements",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationObjets",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationFonctions",
      this.$route.params.id
    );
    this.$store.dispatch(
      "application/fetchApplicationTransformations",
      this.$route.params.id
    );
  },
  computed: {
    // ...mapGetters("capaciteSi", ["CAPACITE_SI", "CAPACITES_SI"]),
    ...mapGetters("application", [
      "APPLICATION",
      "API",
      "CAPACITES",
      "COMPOSANTS",
      "PROCESSUS",
      "BENEFICAIRES",
      "REFERENTS",
      "TRAITEMENTS",
      "OBJECTS",
      "FONCTIONS",
      "TRANSFORMATIONS",
    ]),

    apisList() {
      return this.API.apiExposes || [];
    },
    capacitesList() {
      return this.CAPACITES.capaciteSis || [];
    },
    composantsList() {
      return this.COMPOSANTS.composants || [];
    },
    processusList() {
      return this.PROCESSUS.processus || [];
    },
    beneficairesList() {
      return this.BENEFICAIRES.beneficiaires || [];
    },
    referentsList() {
      return this.REFERENTS.referents || [];
    },
    traitmentsList() {
      return this.TRAITEMENTS.traitements || [];
    },
    objectsList() {
      return this.OBJECTS.objets || [];
    },
    fonctionsList() {
      return this.FONCTIONS.fonctions || [];
    },
    transformationsList() {
      return this.TRANSFORMATIONS.impacts || [];
    },
  },
};
</script>

<style>
.custom-shadow-md {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  padding: 20px;
  border-radius: 10px;
}
</style>
<style>
#mycontainer {
  height: 100vh;
}
</style>