<template>
  <div
    class="
      d-flex
      flex-column
      justify-content-around
      align-items-center
      shdow
      mr-3
    "
    style="width: 180px"
  >
    <p>{{ referent.role }}</p>
    <div style="position: relative">
      <b-avatar
        v-if="referent.user.avatarUrl"
        :src="IMG_URL + referent.user.avatarUrl"
        size="6rem"
      ></b-avatar>
      <b-avatar v-else size="6rem"></b-avatar>
    </div>

    <p class="text-center mt-3 font-weight-bold">
      {{
        referent.user
          ? referent.user.firstname + " " + referent.user.lastname
          : "-"
      }}
    </p>
  </div>
</template>

<script>
import { IMG_URL } from "@/helpers/services/api";
export default {
  props: {
    referent: {
      type: Object,
      default: () => ({
        role: "",
        user: null,
      }),
    },
  },
  data() {
    return {
      IMG_URL,
    };
  },
};
</script>

<style>
</style>